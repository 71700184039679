import { ReactElement } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { CareerSiteProvider } from '../shared/components/career-site-provider';
import { CareerPage } from './career-page';
import { CareersHome } from './careers-home';
import { makeStyles, createStyles } from '@bamboohr/fabric';

const styles = makeStyles(() => {
	return createStyles({
		container: {
			overflow: 'auto',
			minWidth: '400px',
		},
	});
});

export function CareersRoot(): ReactElement {
	const classes = styles();

	return (
		<div className={classes.container}>
			<CareerSiteProvider>
				{/* @ts-ignore */}
				<Router>
					<Switch>
						<Route path='/careers/:id'>
							<CareerPage />
						</Route>
						<Route path='/careers'>
							<CareersHome />
						</Route>
					</Switch>
				</Router>
			</CareerSiteProvider>
		</div>
	);
}
